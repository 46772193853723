.container{
  padding:10px;
}

.topbar{
  align-items: center;
  min-width: 0;
  height: 6.6rem

}

.topbar__logo{
  margin-left: 0;
  flex: 0 1 15rem;
}

.topbar__logo img{
  width: 100%;
}

.topbar__right{
  justify-content: flex-end;
  margin-right: 1.3rem;
}